// src/pages/StreamersPage.js
import React, {useEffect, useState} from 'react';
import {Input, List, Checkbox, Collapse, Layout, Spin} from 'antd';
import {fetchStreamers, fetchStreamParticipants} from '../api/api';
import * as venn from 'venn.js';
import * as d3 from 'd3';

const {Panel} = Collapse;
const {Sider, Content} = Layout;

const StreamersPage = () => {
    const [streamers, setStreamers] = useState([]);
    const [filteredStreamers, setFilteredStreamers] = useState([]);
    const [selectedStreams, setSelectedStreams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingParticipants, setLoadingParticipants] = useState(false);
    const [participantData, setParticipantData] = useState([]);

    useEffect(() => {
        const getStreamers = async () => {
            try {
                const data = await fetchStreamers();
                setStreamers(data);
                setFilteredStreamers(data);
            } catch (error) {
                console.error('Failed to load streamers.');
            } finally {
                setLoading(false);
            }
        };
        getStreamers();
    }, []);

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        const filtered = streamers.filter((streamer) => {
            const matchesStreamerName = streamer.name.toLowerCase().includes(value);
            const matchesStreams = streamer.streams.some((streamId) =>
                streamId.toLowerCase().includes(value)
            );
            return matchesStreamerName || matchesStreams;
        });
        setFilteredStreamers(filtered);
    };

    const handleStreamSelect = async (streamId, checked) => {
        const updatedSelectedStreams = checked
            ? [...selectedStreams, streamId]
            : selectedStreams.filter((id) => id !== streamId);

        setSelectedStreams(updatedSelectedStreams);

        if (updatedSelectedStreams.length > 0) {
            setLoadingParticipants(true);
            try {
                const dataPromises = updatedSelectedStreams.map((id) =>
                    fetchStreamParticipants(id)
                );
                const participantResults = await Promise.all(dataPromises);

                // Формируем данные для кругов Эйлера
                const formattedData = updatedSelectedStreams.map((streamId, index) => ({
                    sets: [streamId],
                    size: participantResults[index].length,
                    participants: participantResults[index],
                }));

                // Добавляем пересечения
                const intersections = [];
                for (let i = 0; i < formattedData.length; i++) {
                    for (let j = i + 1; j < formattedData.length; j++) {
                        const common = formattedData[i].participants.filter((name) =>
                            formattedData[j].participants.includes(name)
                        );
                        if (common.length > 0) {
                            intersections.push({
                                sets: [formattedData[i].sets[0], formattedData[j].sets[0]],
                                size: common.length,
                            });
                        }
                    }
                }

                setParticipantData([...formattedData, ...intersections]);
                renderVennDiagram([...formattedData, ...intersections]);
            } catch (error) {
                console.error('Error loading participants:', error);
            } finally {
                setLoadingParticipants(false);
            }
        } else {
            setParticipantData([]);
            renderVennDiagram([]);
        }
    };

    const renderVennDiagram = (data) => {
        const vennContainer = d3.select('#venn');

        // Проверяем, существует ли контейнер
        if (vennContainer.empty()) {
            console.error('Venn container not found');
            return;
        }

        // Убираем предыдущую диаграмму, если она есть
        vennContainer.selectAll('*').remove();

        // Если данных нет, ничего не рендерим
        if (!data || data.length === 0) {
            // Очищаем блок с данными
            document.getElementById('venn-data').innerHTML = '<p>Нет данных для отображения.</p>';
            return;
        }

        const vennData = data.map((d) => ({
            sets: d.sets.map((set) => `${set} (${getStreamerName(set)})`), // Добавляем никнейм стримера
            size: d.size,
        }));

        const chart = venn.VennDiagram();
        vennContainer.datum(vennData).call(chart);

        // Формируем текстовые данные для отображения под кругами
        const dataHtml = data
            .map((d) => {
                const setsLabel = d.sets.map((set) => `${set} (${getStreamerName(set)})`).join(' ∩ ');
                const totalParticipants = data.reduce((sum, item) => sum + item.size, 0);
                const percentage = ((d.size / totalParticipants) * 100).toFixed(2);

                return `
        <div>
          <strong>${setsLabel}</strong>:
          <span>Количество: ${d.size}, Процент: ${percentage}%</span>
        </div>
      `;
            })
            .join('');

        document.getElementById('venn-data').innerHTML = dataHtml;
    };

// Вспомогательная функция для получения имени стримера по ID
    const getStreamerName = (streamId) => {
        const streamer = streamers.find((streamer) =>
            streamer.streams.includes(streamId)
        );
        return streamer ? streamer.name : 'Неизвестно';
    };


    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider width="20%" style={{background: '#fff', padding: '20px'}}>
                <h1 style={{textAlign: 'center'}}>Список стримеров</h1>
                <Input
                    placeholder="Поиск стримеров или стримов..."
                    allowClear
                    onChange={handleSearch}
                    style={{marginBottom: '20px'}}
                />
                {loading ? (
                    <div style={{textAlign: 'center', marginTop: '50px'}}>
                        <Spin size="large"/>
                    </div>
                ) : (
                    <Collapse accordion>
                        {filteredStreamers.map((streamer) => (
                            <Panel header={streamer.name} key={streamer.name}>
                                <List
                                    dataSource={streamer.streams}
                                    renderItem={(streamId) => (
                                        <List.Item>
                                            <Checkbox
                                                onChange={(e) => handleStreamSelect(streamId, e.target.checked)}
                                            >
                                                {streamId}
                                            </Checkbox>
                                        </List.Item>
                                    )}
                                />
                            </Panel>
                        ))}
                    </Collapse>
                )}
            </Sider>
            <Content style={{padding: '20px', position: 'relative'}}>
                <h2>Пересечения участников</h2>
                <div>
                    <div id="venn" style={{width: '100%', height: '600px'}}></div>
                    <div id="venn-data" style={{marginTop: '20px', fontSize: '16px', lineHeight: '1.5'}}>
                        <p>Нет данных для отображения.</p>
                    </div>
                </div>

            </Content>
        </Layout>
    );
};

export default StreamersPage;
