import axios from 'axios';

const API_BASE_URL = 'https://twitch.etsite.space';

export const fetchStreamers = async () => {
    try {
        const response = await axios.get(API_BASE_URL + '/streamers/');
        return response.data;
    } catch (error) {
        console.error('Error fetching streamers:', error);
        throw error;
    }
};

export const fetchStreamParticipants = async (streamId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/stream/${streamId}`);
        return response.data['nicknames']; // Предполагается, что это массив никнеймов
    } catch (error) {
        console.error(`Error fetching participants for stream ${streamId}:`, error);
        throw error;
    }
};